import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen1 } from '../../../components/images/support/tasks/Screen1.generated';
import { Screen2 } from '../../../components/images/support/tasks/Screen2.generated';
import { Screen3 } from '../../../components/images/support/tasks/Screen3.generated';
import { Screen4 } from '../../../components/images/support/tasks/Screen4.generated';
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tasks-"
    }}>{`Tasks 📋✔`}</h1>
    <p>{`This section is about the types of tasks available in our Workflows. `}</p>
    <p>{`Tasks are the building blocks of Workflows.`}</p>
    <p>{`Think of tasks as the jobs we want a member or an organisation (eg: employee, manager, owner, bookkeeper) to do.`}</p>
    <p>{`Some example of what we can do with tasks:`}</p>
    <ul>
      <li parentName="ul">{`Ask a member to fill in a form `}</li>
      <li parentName="ul">{`Ask a member to upload a document`}</li>
      <li parentName="ul">{`Provide a member with some information`}</li>
    </ul>
    <p>{`Let's learn how to add tasks to our Workflows`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Tasks can be found in the `}<strong parentName="li">{`Workflows`}</strong>{` menu. Click into an existing Workflow or create a new one.`}</li>
    </ol>

    <Screen1 mdxType="Screen1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Add task`}</strong>{`.`}</li>
    </ol>

    <Screen2 mdxType="Screen2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Then choose from the different types of tasks to add to your Workflow.`}</li>
    </ol>

    <Screen3 mdxType="Screen3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Any time we create or edit a task we need to save the Workflow.`}</li>
    </ol>

    <Screen4 mdxType="Screen4" />
    <h3 {...{
      "id": "learn-more-about-the-different-task-types-in-the-next-section"
    }}>{`Learn more about the different task types in the next section.`}</h3>

    <SupportFooter linkTo="/support/tasks/task-types" linkText="Learn about task types" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      